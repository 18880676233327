/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const animals : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["PETS_ANIMAL", "Zviera"],
        ["PETS_FEED", "Krmivo"],
        ["PETS_REWARD", "Pamlsky, odmeny"],
        ["PETS_VITAMINS", "Vitam\u00edny a lie\u010div\u00e1"],
        ["PETS_HOUSE", "B\u00fada, akv\u00e1rium, klietka"],
        ["PETS_CUSHION", "Vank\u00fa\u0161, pelech, le\u017eadlo"],
        ["PETS_BOWL", "Nap\u00e1ja\u010dka a miska na potravu"],
        ["PETS_TOY", "Hra\u010dky"],
        ["PETS_TRAVEL", "Cestovanie, preprava"],
        ["PETS_CLOTHES", "Oble\u010denie"],
        ["PETS_COSMETICS", "Kozmetika a \u00faprava"],
        ["PETS_PLANT", "Rastliny, dekor\u00e1cie"],
        ["PETS_SET", "Kompletn\u00e1 v\u00fdbava"],
        ["COLLAR_HALTER_LEASH", "Obojok, ohl\u00e1vka, vod\u00edtko"],
        ["LITERATURE", "Literat\u00fara"],
        ["PETS_OTHER", "In\u00e9"],
    ],
    p_animal: [
        ["DOG", "Pes"],
        ["CAT", "Ma\u010dka"],
        ["BIRD", "Vt\u00e1ky"],
        ["FISH", "Ryby a korytna\u010dky"],
        ["HAMSTER", "\u0160kre\u010dok, mor\u010da, kr\u00e1lik"],
        ["LIZARD", "Ter\u00e1ristika"],
        ["FARM_ANIMALS", "Hospod\u00e1rske zvierat\u00e1"],
        ["PETS_OTHER", "In\u00e9"],
    ],
};
