// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const BY_CREATION_TIME_NEWEST = "Pridan\u00e9: ned\u00e1vno";
export const BY_CREATION_TIME_OLDEST = "Pridan\u00e9: d\u00e1vno";
export const BY_NEW_MESSAGES = "Pod\u013ea najnov\u0161\u00edch spr\u00e1v";
export const BY_PRICE_ASC = "Cena: najlacnej\u0161ie";
export const BY_PRICE_DESC = "Cena: najdrah\u0161ie";
export const BY_REPOST_TIME_NEWEST = "Aktualizovan\u00e9: ned\u00e1vno";
export const BY_REPOST_TIME_OLDEST = "Aktualizovan\u00e9: d\u00e1vno";
export const BY_TITLE = "Abecedne: A-Z";
export const BY_TITLE_REVERSED = "Abecedne: Z-A";
export const BZ2_CAT_ACCOMMODATION = "Ubytovanie";
export const BZ2_CAT_ACCOMMODATION_RENTAL = "Pren\u00e1jom b\u00fdvania";
export const BZ2_CAT_ADULTS_HANDICRAFTS = "Ru\u010dn\u00e9 pr\u00e1ce (nie v\u00fdrobky)";
export const BZ2_CAT_ADULTS_HEALTH_BEAUTY = "Kr\u00e1sa a zdravie";
export const BZ2_CAT_ADULTS_HOMEWARE = "In\u00e9 do dom\u00e1cnosti";
export const BZ2_CAT_ADULTS_MISC = "In\u00e9 pre dospel\u00fdch";
export const BZ2_CAT_ANIMALS = "Zvierat\u00e1";
export const BZ2_CAT_ANTIQUE_ART_OBJECTS = "Umeleck\u00e9 predmety";
export const BZ2_CAT_ANTIQUE_BADGES = "Odznaky a vojenstvo";
export const BZ2_CAT_ANTIQUE_BOOKS = "Star\u00e9 knihy a tla\u010de";
export const BZ2_CAT_ANTIQUE_CERAMICS = "Keramika, sklo, porcel\u00e1n";
export const BZ2_CAT_ANTIQUE_CLOCKS = "Hodiny";
export const BZ2_CAT_ANTIQUE_FURNITURE = "Staro\u017eitn\u00fd n\u00e1bytok";
export const BZ2_CAT_ANTIQUE_JEWELRY = "Zlato, striebro, \u0161perky";
export const BZ2_CAT_ANTIQUE_MODELS = "Modely";
export const BZ2_CAT_ANTIQUE_MONEY = "Mince, bankovky";
export const BZ2_CAT_ANTIQUE_MUSICAL_INSTRUMENTS = "Hudobn\u00e9 n\u00e1stroje";
export const BZ2_CAT_ANTIQUE_OTHERS = "Ostatn\u00e9";
export const BZ2_CAT_ANTIQUE_PICTURES = "Obrazy, obr\u00e1zky";
export const BZ2_CAT_ANTIQUE_POSTERS = "Poh\u013eadnice, fotky, plag\u00e1ty";
export const BZ2_CAT_ANTIQUES = "Staro\u017eitnosti, zberate\u013estvo";
export const BZ2_CAT_ANTIQUE_STAMPS = "Zn\u00e1mky";
export const BZ2_CAT_ANTIQUE_TECHNOLOGY = "R\u00e1di\u00e1, fo\u0165\u00e1ky, technika";
export const BZ2_CAT_ANTIQUE_TOYS = "Hra\u010dky retro a staro\u017eitn\u00e9";
export const BZ2_CAT_ARCHERY = "Lukostre\u013eba";
export const BZ2_CAT_ATVS = "\u0160tvorkolky";
export const BZ2_CAT_AUTOMOTO = "Auto, moto";
export const BZ2_CAT_AUTOMOTO_SPARE_PARTS = "N\u00e1hradn\u00e9 diely";
export const BZ2_CAT_BABY_CARRIERS = "\u0160atky, nosi\u010de, klokanky";
export const BZ2_CAT_BABY_FEEDING = "Potreby pre doj\u010dat\u00e1";
export const BZ2_CAT_BABY_MISC = "In\u00e9 detsk\u00e9";
export const BZ2_CAT_BABYSITTING = "Str\u00e1\u017eenie det\u00ed";
export const BZ2_CAT_BALANCE_BIKE = "Cykloodr\u00e1\u017eadl\u00e1";
export const BZ2_CAT_BALL_GAMES = "Loptov\u00e9 hry";
export const BZ2_CAT_BALL_GAMES_BALLS = "Lopty";
export const BZ2_CAT_BALL_GAMES_OTHER = "Ostatn\u00e9";
export const BZ2_CAT_BASEBALL = "Baseball, softball";
export const BZ2_CAT_BASEBALL_BATS = "Rukavice, p\u00e1lky, lopti\u010dky";
export const BZ2_CAT_BASEBALL_OTHER = "In\u00e9";
export const BZ2_CAT_BASKETBALL = "Basketbal";
export const BZ2_CAT_BASKETBALL_BALLS = "Lopty";
export const BZ2_CAT_BASKETBALL_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_BASKETBALL_FOOTWEAR = "Obuv";
export const BZ2_CAT_BASKETBALL_OTHER = "Pr\u00edslu\u0161enstvo";
export const BZ2_CAT_BEACH_TENTS_CHAIRS = "Pl\u00e1\u017eov\u00e9 stany, leh\u00e1tka";
export const BZ2_CAT_BICYCLE = "Bicykle";
export const BZ2_CAT_BICYCLE_SEAT = "Cykloseda\u010dka";
export const BZ2_CAT_BICYCLE_TRAILER = "Cyklovoz\u00edky, miminkovn\u00edky";
export const BZ2_CAT_BIRTH = "Pom\u00f4cky pre p\u00f4rod a \u0161estonedelie";
export const BZ2_CAT_BLANKETS_WRAPPERS = "Deky, zavinova\u010dky";
export const BZ2_CAT_BLOUSE_SHIRTS = "Ko\u0161ele, bl\u00fazky";
export const BZ2_CAT_BOATING = "Vod\u00e1ctvo";
export const BZ2_CAT_BOATING_BARRELS = "Sudy, vaky";
export const BZ2_CAT_BOATING_BOATS = "Lode, rafty";
export const BZ2_CAT_BOATING_LIFE_JACKETS = "Z\u00e1chrann\u00e9 vesty, helmy";
export const BZ2_CAT_BOATING_OTHER = "In\u00e9";
export const BZ2_CAT_BOATING_PADDLES = "Vesl\u00e1";
export const BZ2_CAT_BODYSUITS = "Body";
export const BZ2_CAT_BODYSUITS_SLEEPSUITS_ROMPERS = "Dupa\u010dky, body";
export const BZ2_CAT_BOUNCERS = "Leh\u00e1tka a ha\u010dky pre b\u00e1b\u00e4tko";
export const BZ2_CAT_BREASTFEEDING_ACCESSORIES = "Potreby pre doj\u010diace matky";
export const BZ2_CAT_BUILDING_AND_CONSTRUCTION_MATERIAL = "Stavba a stavebn\u00fd materi\u00e1l";
export const BZ2_CAT_CAMERA_PHOTO_VIDEO = "Fotoapar\u00e1ty a videokamery";
export const BZ2_CAT_CARETAKING = "Starostlivos\u0165 o Z\u0164P a seniorov";
export const BZ2_CAT_CARS = "Osobn\u00e9 aut\u00e1";
export const BZ2_CAT_CAR_SEATS = "Autoseda\u010dky";
export const BZ2_CAT_CAR_SEATS_ACCESSORIES = "Pr\u00edslu\u0161enstvo alebo in\u00e9";
export const BZ2_CAT_CAR_SEATS_AND_ACCESSORIES = "Autoseda\u010dky a pr\u00edslu\u0161enstvo";
export const BZ2_CAT_CELEBRATIONS = "Karneval, svadba, oslava";
export const BZ2_CAT_CHANGING_BAG = "Preba\u013eovacia ta\u0161ka, batoh";
export const BZ2_CAT_CHILDREN_CAMPS = "Detsk\u00e9 a pr\u00edmestsk\u00e9 t\u00e1bory";
export const BZ2_CAT_CHILDREN_CARE = "Starostlivos\u0165 o deti";
export const BZ2_CAT_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_CLOTHING_MEGAPACKS = "Bal\u00edky / mix oble\u010denia";
export const BZ2_CAT_COSMETICS_BATHING = "K\u00fapanie, preba\u013eovanie a hygiena";
export const BZ2_CAT_COTS = "Postele, ohr\u00e1dky";
export const BZ2_CAT_CRAFTSMEN = "Remeseln\u00edci";
export const BZ2_CAT_CROSSCOUNTRY_SKIING = "Be\u017eky";
export const BZ2_CAT_CROSSCOUNTRY_SKIING_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_CROSSCOUNTRY_SKIING_FOOTWEAR = "Obuv";
export const BZ2_CAT_CROSSCOUNTRY_SKIING_OTHER = "In\u00e9";
export const BZ2_CAT_CROSSCOUNTRY_SKIS = "Be\u017eky";
export const BZ2_CAT_CYCLING = "Cyklistika";
export const BZ2_CAT_CYCLING_ACCESSORIES = "Pr\u00edslu\u0161enstvo";
export const BZ2_CAT_CYCLING_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_CYCLING_FOOTWEAR = "Obuv";
export const BZ2_CAT_CYCLING_HELMETS = "Helmy, chr\u00e1ni\u010de";
export const BZ2_CAT_CYCLING_OTHER = "In\u00e9";
export const BZ2_CAT_DANCING = "Tanec";
export const BZ2_CAT_DANCING_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_DANCING_FOOTWEAR = "Obuv";
export const BZ2_CAT_DANCING_OTHER = "Doplnky";
export const BZ2_CAT_DARTS = "\u0160\u00edpky";
export const BZ2_CAT_DINING = "Jed\u00e1lensk\u00e9 stoli\u010dky a potreby";
export const BZ2_CAT_DINING_OTHER = "Jed\u00e1lensk\u00e9 potreby";
export const BZ2_CAT_DISINFECTION = "Dezinfekcia";
export const BZ2_CAT_DRESSES = "\u0160aty";
export const BZ2_CAT_ELECTRIC_BICYCLE = "Elektrobicykle";
export const BZ2_CAT_ELECTRONICS = "Mobily, foto, elektronika";
export const BZ2_CAT_EMPLOYMENT = "Zamestnanie";
export const BZ2_CAT_EXERCISE_MATS = "Podlo\u017eky na cvi\u010denie";
export const BZ2_CAT_FACE_MASKS = "R\u00fa\u0161ka";
export const BZ2_CAT_FACE_PAINTING = "Ma\u013eovanie na tv\u00e1r";
export const BZ2_CAT_FACE_SHIELDS = "Ochrann\u00e9 \u0161t\u00edty";
export const BZ2_CAT_FANCY_DRESS = "Karneval";
export const BZ2_CAT_FASHION_ACCESSORIES = "M\u00f3dne doplnky";
export const BZ2_CAT_FASHION_ACCESSORIES_ACCESSORIES = "M\u00f3dne doplnky";
export const BZ2_CAT_FASHION_ACCESSORIES_HANDBAGS = "Kabelky, batohy, kufre";
export const BZ2_CAT_FASHION_ACCESSORIES_JEWELRY = "\u0160perky, bi\u017eut\u00e9ria";
export const BZ2_CAT_FEET_TROUSERS = "Polodupa\u010dky";
export const BZ2_CAT_FIGHTING = "Bojov\u00e9 \u0161porty";
export const BZ2_CAT_FIGHTING_ACCESSORIES = "Doplnky";
export const BZ2_CAT_FIGHTING_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_FIGHTING_OTHER = "Pr\u00edslu\u0161enstvo";
export const BZ2_CAT_FINANCE = "Financie";
export const BZ2_CAT_FISHING = "Rybolov";
export const BZ2_CAT_FISHING_BOATS = "\u010clny";
export const BZ2_CAT_FISHING_CHAIRS = "Stoli\u010dky, kresl\u00e1, leh\u00e1tka";
export const BZ2_CAT_FISHING_OTHER = "In\u00e9";
export const BZ2_CAT_FISHING_RODS = "Udice, navij\u00e1ky";
export const BZ2_CAT_FISHING_SHELTERS = "D\u00e1\u017edniky, pr\u00edstre\u0161ky";
export const BZ2_CAT_FITNESS_MACHINES = "Posil\u0148ovacie stroje";
export const BZ2_CAT_FLOORBALL = "Florbal, hokejbal";
export const BZ2_CAT_FLOORBALL_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_FLOORBALL_OTHER = "Ostatn\u00e9";
export const BZ2_CAT_FLOORBALL_STICKS = "Hokejky";
export const BZ2_CAT_FOOD = "Potraviny";
export const BZ2_CAT_FOOTBALL = "Futbal, futsal";
export const BZ2_CAT_FOOTBALL_BALLS = "Lopty";
export const BZ2_CAT_FOOTBALL_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_FOOTBALL_FOOTWEAR = "Kopa\u010dky";
export const BZ2_CAT_FOOTBALL_OTHER = "In\u00e9";
export const BZ2_CAT_FOOTMUFFS = "Fusaky";
export const BZ2_CAT_FOOTWEAR = "Obuv";
export const BZ2_CAT_GARDEN = "Z\u00e1hrada";
export const BZ2_CAT_GIFTS = "Dar\u010dekov\u00e9 a spomienkov\u00e9 predmety";
export const BZ2_CAT_GOLF = "Golf";
export const BZ2_CAT_GOLF_ACCESSORIES = "Doplnky";
export const BZ2_CAT_GOLF_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_GOLF_CLUBS = "Palice";
export const BZ2_CAT_GOLF_FOOTWEAR = "Obuv";
export const BZ2_CAT_GOLF_OTHER = "In\u00e9";
export const BZ2_CAT_GUIDE_BAR = "Vodiace a spojovacie ty\u010de";
export const BZ2_CAT_GYMNASTICS_BALLET = "Gymnastika, balet";
export const BZ2_CAT_GYMNASTICS_BALLET_ACCESSORIES = "Doplnky";
export const BZ2_CAT_GYMNASTICS_BALLET_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_GYMNASTICS_BALLET_FOOTWEAR = "Obuv";
export const BZ2_CAT_GYMNASTICS_BALLET_OTHER = "In\u00e9";
export const BZ2_CAT_HATS_MITTENS_SCARVES = "\u010ciapky, rukavice, \u0161\u00e1ly";
export const BZ2_CAT_HEALTH_AND_BEAUTY = "Zdravie a kr\u00e1sa";
export const BZ2_CAT_HEAVY_MACHINERY = "Pracovn\u00e9 stroje";
export const BZ2_CAT_HIGH_CHAIRS = "Jed\u00e1lensk\u00e1 stoli\u010dka";
export const BZ2_CAT_HIKING = "Turistika, kempovanie";
export const BZ2_CAT_HIKING_BABY_CARRIES = "Nosi\u010de det\u00ed";
export const BZ2_CAT_HIKING_BACKPACK = "Batohy";
export const BZ2_CAT_HIKING_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_HIKING_FOOTWEAR = "Obuv";
export const BZ2_CAT_HIKING_MATS = "Karimatky, matrace";
export const BZ2_CAT_HIKING_OTHER = "Pr\u00edslu\u0161enstvo a in\u00e9";
export const BZ2_CAT_HIKING_POLES = "Turistick\u00e9 palice";
export const BZ2_CAT_HIKING_SLEEPING_BAGS = "Spacie vaky";
export const BZ2_CAT_HIKING_TENTS = "Stany";
export const BZ2_CAT_HOBBY_CLASSES = "Z\u00e1ujmov\u00e9 kr\u00fa\u017eky";
export const BZ2_CAT_HOCKEY_STICKS_PUCKS = "Hokejky, puky";
export const BZ2_CAT_HOME_ACCESSORIES_DECORATIONS = "Bytov\u00e9 doplnky a dekor\u00e1cie";
export const BZ2_CAT_HOME_APPLIANCES = "Spotrebi\u010de do dom\u00e1cnosti";
export const BZ2_CAT_HOME_CARE_CLEANING = "Drog\u00e9ria, upratovanie a \u017eehlenie";
export const BZ2_CAT_HOME_CARPETS_RUGS = "Koberce a roho\u017eky";
export const BZ2_CAT_HOME_COOKING_BAKING_WARE = "Varenie, pe\u010denie";
export const BZ2_CAT_HOME_DINING = "Stolovanie";
export const BZ2_CAT_HOME_FOOD_STORAGE = "Skladovanie a preprava potrav\u00edn";
export const BZ2_CAT_HOME_FURNITURE = "N\u00e1bytok";
export const BZ2_CAT_HOME_LIGHTING = "Lampy a osvetlenie";
export const BZ2_CAT_HOME_LIVING = "Dom\u00e1cnos\u0165";
export const BZ2_CAT_HOME_TEXTILES = "Text\u00edlie";
export const BZ2_CAT_HORSEBACK_RIDING = "Jazda na koni";
export const BZ2_CAT_HORSEBACK_RIDING_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_HORSEBACK_RIDING_FOOTWEAR = "Obuv";
export const BZ2_CAT_HORSEBACK_RIDING_HELMETS = "Prilby, chr\u00e1ni\u010de";
export const BZ2_CAT_HORSEBACK_RIDING_OTHER = "In\u00e9";
export const BZ2_CAT_HOUSE_AND_GARDEN = "Dom a z\u00e1hrada";
export const BZ2_CAT_HOUSEHOLD_HELP = "Pomoc v dom\u00e1cnosti";
export const BZ2_CAT_HUNTING = "Po\u013eovn\u00edctvo";
export const BZ2_CAT_ICE_HOCKEY = "Hokej, krasokor\u010du\u013eovanie ";
export const BZ2_CAT_ICE_HOCKEY_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_ICE_HOCKEY_FOOTWEAR = "Kor\u010dule";
export const BZ2_CAT_ICE_HOCKEY_HELMETS = "Prilby, chr\u00e1ni\u010de, rukavice";
export const BZ2_CAT_ICE_HOCKEY_OTHER = "In\u00e9";
export const BZ2_CAT_INLINE_SKATES = "In-line (kolie\u010dka za sebou)";
export const BZ2_CAT_INTERNSHIPS = "Prax a st\u00e1\u017ee";
export const BZ2_CAT_JACKETS_COATS = "Bundy, kab\u00e1ty, sak\u00e1";
export const BZ2_CAT_JOBS = "Pr\u00e1ca, brig\u00e1dy";
export const BZ2_CAT_KIDS_PARTY = "Oslava";
export const BZ2_CAT_KINDERGARDEN_SCHOOL = "\u0160k\u00f4lk\u00e1ri a \u0161kol\u00e1ci";
export const BZ2_CAT_LEGGINGS_JEGGINGS = "Leg\u00edny, d\u017eeg\u00edny";
export const BZ2_CAT_MAJORETTES = "Ma\u017eoretky, roztlieskava\u010dky";
export const BZ2_CAT_MAJORETTES_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_MAJORETTES_OTHER = "Doplnky";
export const BZ2_CAT_MEDICAL_TESTS = "Testy";
export const BZ2_CAT_MISC = "In\u00e9";
export const BZ2_CAT_MISC_MOTHERHOOD = "Pom\u00f4cky pre tehotenstvo";
export const BZ2_CAT_MOTHERS_NOURISHMENT = "V\u00fd\u017eivov\u00e9 doplnky pre tehotn\u00e9 a mami\u010dky";
export const BZ2_CAT_MOTOCROSS = "Motokros";
export const BZ2_CAT_MOTOCROSS_CLOTHING = "Oble\u010denie, obuv";
export const BZ2_CAT_MOTOCROSS_HELMETS = "Prilby, chr\u00e1ni\u010de";
export const BZ2_CAT_MOTOCROSS_MACHINES = "Motok\u00e1ry a pod.";
export const BZ2_CAT_MOTOCROSS_OTHER = "In\u00e9";
export const BZ2_CAT_MOTORCYCLES = "Motorky";
export const BZ2_CAT_MOTORHOMES = "Obytn\u00e9 vozy";
export const BZ2_CAT_MOUNTAINEERING = "Horolezectvo";
export const BZ2_CAT_MOUNTAINEERING_CLIPS = "Poistky a viazanie";
export const BZ2_CAT_MOUNTAINEERING_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_MOUNTAINEERING_FOOTWEAR = "Obuv";
export const BZ2_CAT_MOUNTAINEERING_HELMETS = "Prilby";
export const BZ2_CAT_MOUNTAINEERING_OTHER = "Pr\u00edslu\u0161enstvo";
export const BZ2_CAT_MULTIMEDIA = "Knihy, hudba a film";
export const BZ2_CAT_MULTIMEDIA_AUDIO = "Hudba a audio";
export const BZ2_CAT_MULTIMEDIA_BOOKS = "Knihy";
export const BZ2_CAT_MULTIMEDIA_CALENDARS = "Kalend\u00e1re, di\u00e1re";
export const BZ2_CAT_MULTIMEDIA_COLORING = "Oma\u013eov\u00e1nky";
export const BZ2_CAT_MULTIMEDIA_MAGAZINES = "\u010casopisy";
export const BZ2_CAT_MULTIMEDIA_VIDEO = "Film a video";
export const BZ2_CAT_NAPPIES = "Plienky";
export const BZ2_CAT_NOURISHMENT = "Detsk\u00e1 v\u00fd\u017eiva, potravinov\u00e9 doplnky";
export const BZ2_CAT_NURSERY = "Detsk\u00e1 izba";
export const BZ2_CAT_NURSING_BRA = "Podprsenky na doj\u010denie";
export const BZ2_CAT_OCCASION_WEAR = "Sl\u00e1vnostn\u00e9 a viano\u010dn\u00e9 oble\u010denie";
export const BZ2_CAT_OTHER_ELECTRONICS = "In\u00e1 elektronika";
export const BZ2_CAT_OTHER_SERVICES = "In\u00e9";
export const BZ2_CAT_OUTFITS = "Komplety, s\u00fapravy";
export const BZ2_CAT_OVERALLS = "Overaly";
export const BZ2_CAT_PC_GAMING = "Hern\u00e9 konzoly a hry";
export const BZ2_CAT_PC_NOTEBOOKS = "Notebooky a po\u010d\u00edta\u010de";
export const BZ2_CAT_PHONES_TABLETS_EREADERS = "Mobily, tablety a \u010d\u00edta\u010dky kn\u00edh";
export const BZ2_CAT_PHOTOGRAPHY = "Fotenie";
export const BZ2_CAT_PING_PONG_BEDMINTON = "Ping pong, bedminton, soft tenis";
export const BZ2_CAT_PING_PONG_BEDMINTON_BALLS = "Lopti\u010dky, ko\u0161\u00edky";
export const BZ2_CAT_PING_PONG_BEDMINTON_OTHER = "Pr\u00edslu\u0161enstvo";
export const BZ2_CAT_PING_PONG_BEDMINTON_RACKETS = "Rakety";
export const BZ2_CAT_PRAMS = "Ko\u010d\u00edky";
export const BZ2_CAT_PRAMS_ACCESSORIES = "Pr\u00edslu\u0161enstvo";
export const BZ2_CAT_PRAMS_AND_ACCESSORIES = "Ko\u010d\u00edky a fusaky";
export const BZ2_CAT_PREGNANCY_CLOTHING = "Oble\u010denie pre tehotn\u00e9";
export const BZ2_CAT_PREGNANCY_MOTHERS = "Tehotenstvo doj\u010denie";
export const BZ2_CAT_PROTECTIVE_EQUIPMENT = "Ochrann\u00e9 pom\u00f4cky";
export const BZ2_CAT_PROTECTIVE_EQUIPMENT_OTHER = "In\u00e9";
export const BZ2_CAT_RAINWEAR = "Oble\u010denie do da\u017e\u010fa";
export const BZ2_CAT_RENTAL = "Po\u017ei\u010dov\u0148a";
export const BZ2_CAT_RESPIRATORS = "Respir\u00e1tory";
export const BZ2_CAT_RETRAINING = "Rekvalifik\u00e1cie, kurzy";
export const BZ2_CAT_ROLLER_SKATES = "Kolieskov\u00e9 kor\u010dule (kolie\u010dka ved\u013ea seba)";
export const BZ2_CAT_ROLLER_SKATING = "In-line, kolieskov\u00e9 kor\u010dule";
export const BZ2_CAT_ROLLER_SKATING_HELMETS = "Prilby a chr\u00e1ni\u010de";
export const BZ2_CAT_ROLLER_SKATING_OTHER = "Pr\u00edslu\u0161enstvo a in\u00e9";
export const BZ2_CAT_ROMPERS = "Opalova\u010dky, letn\u00fd overal";
export const BZ2_CAT_RUNNING = "Beh, atletika";
export const BZ2_CAT_RUNNING_ACCESSORIES = "Doplnky";
export const BZ2_CAT_RUNNING_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_RUNNING_FOOTWEAR = "Obuv";
export const BZ2_CAT_RUNNING_OTHER = "Pr\u00edslu\u0161enstvo a in\u00e9";
export const BZ2_CAT_SAFETY = "Monitory dychu, pest\u00fanky a bezpe\u010dnos\u0165";
export const BZ2_CAT_SAFETY_GLASSES = "Ochrann\u00e9 okuliare";
export const BZ2_CAT_SAFETY_GLOVES = "Rukavice";
export const BZ2_CAT_SCOOTER = "Kolobe\u017eky";
export const BZ2_CAT_SEO_BABY_ACCESSORIES = "Detsk\u00e9 m\u00f3dne doplnky";
export const BZ2_CAT_SEO_BLOUSE_SHIRTS = "Ko\u0161ele, bl\u00fazky";
export const BZ2_CAT_SEO_BODYSUITS_SLEEPSUITS_ROMPERS = "Dupa\u010dky, body";
export const BZ2_CAT_SEO_CLOTHING_MEGAPACKS = "Bal\u00edky / mix oble\u010denia ";
export const BZ2_CAT_SEO_DRESSES = "\u0160aty";
export const BZ2_CAT_SEO_DRESSES_SKIRTS = "Sukne, \u0161aty";
export const BZ2_CAT_SEO_HATS_MITTENS_SCARVES = "\u010ciapky, rukavice, \u0161\u00e1ly";
export const BZ2_CAT_SEO_JACKETS_COATS = "Bundy, kab\u00e1ty, sak\u00e1";
export const BZ2_CAT_SEO_OCCASION_WEAR = "Sl\u00e1vnostn\u00e9 a viano\u010dn\u00e9 oble\u010denie";
export const BZ2_CAT_SEO_OUTFITS = "Komplety, s\u00fapravy";
export const BZ2_CAT_SEO_SHORTS_CROPS = "\u0160ortky, tri\u0161tvr\u0165\u00e1ky";
export const BZ2_CAT_SEO_SKIRTS = "Sukne";
export const BZ2_CAT_SEO_SLEEPING_BAGS = "Detsk\u00e9 spacie vaky";
export const BZ2_CAT_SEO_SLEEPWEAR = "Py\u017eam\u00e1, no\u010dn\u00e9 ko\u0161ele a \u017eupany";
export const BZ2_CAT_SEO_SNOWSUITS = "Zimn\u00e9 kombin\u00e9zy a otep\u013eova\u010dky";
export const BZ2_CAT_SEO_SOCKS_TIGHTS = "Pan\u010du\u0161ky, pono\u017eky, \u0161tucne";
export const BZ2_CAT_SEO_SWEATSHIRTS_JUMPERS_VESTS = "Mikiny, svetre, bolerk\u00e1";
export const BZ2_CAT_SEO_SWIMWEAR_DRESSING_GOWNS = "Plavky";
export const BZ2_CAT_SEO_TROUSERS_JEANS_JOGGERS = "Nohavice, rifle, tepl\u00e1ky";
export const BZ2_CAT_SEO_TSHIRTS_TOPS = "Tri\u010dk\u00e1, tielka";
export const BZ2_CAT_SEO_TWINS = "Oble\u010denie a obuv pre dvoji\u010dky";
export const BZ2_CAT_SEO_TWINS_MISC = "R\u00f4zne pre dvoji\u010dky (ne ko\u010d\u00edky)";
export const BZ2_CAT_SEO_UNDERWEAR = "Spodn\u00e1 bielize\u0148";
export const BZ2_CAT_SERVICES = "Slu\u017eby";
export const BZ2_CAT_SHORTS_CROPS = "\u0160ortky, tri\u0161tvr\u0165\u00e1ky";
export const BZ2_CAT_SKATEBOARDS = "Skateboard a pod.";
export const BZ2_CAT_SKATEBOARDS_BOARDS = "Boardy";
export const BZ2_CAT_SKATEBOARDS_HELMETS = "Prilby, chr\u00e1ni\u010de";
export const BZ2_CAT_SKATEBOARDS_OTHER = "In\u00e9";
export const BZ2_CAT_SKI_FOOTWEAR = "Ly\u017eiarske top\u00e1nky";
export const BZ2_CAT_SKI_GOGGLES = "Okuliare";
export const BZ2_CAT_SKI_HELMETS = "Prilby";
export const BZ2_CAT_SKI_POLES = "Palice";
export const BZ2_CAT_SKIRTS = "Sukne";
export const BZ2_CAT_SKIS = "Ly\u017ee";
export const BZ2_CAT_SKIS_SNOWBOARDS = "Ly\u017ee, snowboard";
export const BZ2_CAT_SKIS_SNOWBOARDS_OTHER = "In\u00e9";
export const BZ2_CAT_SLEDGES_BOBSLEIGHS = "S\u00e1nky, boby";
export const BZ2_CAT_SLEEPING_BAGS = "Spacie vaky";
export const BZ2_CAT_SLEEPSUITS = "Overaly, dupa\u010dky";
export const BZ2_CAT_SLEEPWEAR = "Py\u017eam\u00e1, no\u010dn\u00e9 ko\u0161ele, \u017eupany";
export const BZ2_CAT_SNORKELS_AND_FINS = "\u0160norchle a plutvy";
export const BZ2_CAT_SNOWBOARD_FOOTWEAR = "Top\u00e1nky na snowboard";
export const BZ2_CAT_SNOWBOARDS = "Snowboardy";
export const BZ2_CAT_SNOWSUITS = "Oble\u010denie do snehu";
export const BZ2_CAT_SOCKS_TIGHTS = "Pan\u010du\u0161ky, pono\u017eky, \u0161tucne";
export const BZ2_CAT_SPORT = "\u0160port";
export const BZ2_CAT_SPORT_BOTTLES = "\u0160portov\u00e9 f\u013ea\u0161e";
export const BZ2_CAT_SPORT_BOTTLES_BOTTLES = "F\u013ea\u0161e, termof\u013ea\u0161e";
export const BZ2_CAT_SPORT_BOTTLES_OTHER = "Pr\u00edslu\u0161enstvo";
export const BZ2_CAT_SPORT_MASSAGE = "\u0160portov\u00e9 mas\u00e1\u017ee";
export const BZ2_CAT_SPORT_OTHERS = "In\u00e9";
export const BZ2_CAT_SPORT_OTHERS_ACCESSORIES = "Doplnky";
export const BZ2_CAT_SPORT_OTHERS_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_SPORT_OTHERS_FOOTWEAR = "Obuv";
export const BZ2_CAT_SPORT_OTHERS_OTHER = "In\u00e9";
export const BZ2_CAT_SPORTS_BAGS = "\u0160portov\u00e9 ta\u0161ky, batohy";
export const BZ2_CAT_SPORT_VITAMINS = "V\u00fd\u017eiva a vitam\u00edny pre \u0161portovcov";
export const BZ2_CAT_SPORT_WATCHES = "\u0160portov\u00e9 hodinky a n\u00e1ramky";
export const BZ2_CAT_STRENGHTENING_AIDS = "Pom\u00f4cky pre posil\u0148ovanie";
export const BZ2_CAT_SWEATSHIRTS_JUMPERS_VESTS = "Mikiny, svetre, bolerk\u00e1";
export const BZ2_CAT_SWIMMING_CAPS = "\u010ciapky";
export const BZ2_CAT_SWIMMING_CIRCLES_SLEEVES = "Kruhy, ruk\u00e1vniky a pod.";
export const BZ2_CAT_SWIMMING_DIVING = "Pl\u00e1vanie, pot\u00e1panie";
export const BZ2_CAT_SWIMMING_GOGGLES = "Okuliare";
export const BZ2_CAT_SWIMMING_NEOPRENES = "Neopr\u00e9ny";
export const BZ2_CAT_SWIMMING_OTHER = "In\u00e9";
export const BZ2_CAT_SWIMWEAR_DRESSING_GOWNS = "Plavky";
export const BZ2_CAT_TENNIS_SQUASH = "Tenis, squash";
export const BZ2_CAT_TENNIS_SQUASH_BALLS = "Lopti\u010dky";
export const BZ2_CAT_TENNIS_SQUASH_CLOTHING = "Oble\u010denie";
export const BZ2_CAT_TENNIS_SQUASH_FOOTWEAR = "Obuv";
export const BZ2_CAT_TENNIS_SQUASH_OTHER = "In\u00e9";
export const BZ2_CAT_TENNIS_SQUASH_RACKETS = "Rakety";
export const BZ2_CAT_THERMOMETERS = "Teplomery";
export const BZ2_CAT_TICKETS_VOUCHERS = "Vstupenky, poukazy";
export const BZ2_CAT_TO_THE_GARAGE = "Do gar\u00e1\u017ee";
export const BZ2_CAT_TOYS = "Hra\u010dky a hry";
export const BZ2_CAT_TRAMPOLINES = "Trampol\u00edny";
export const BZ2_CAT_TRAMPOLINES_SPARE_PARTS = "N\u00e1hradn\u00e9 diely";
export const BZ2_CAT_TRAMPOLINES_STAIRS = "Schody";
export const BZ2_CAT_TRAMPOLINES_TRAMPOLINES = "Trampol\u00edny";
export const BZ2_CAT_TRIKE = "Trojkolky";
export const BZ2_CAT_TROLLEYS_TRAILERS = "Voz\u00edk, pr\u00edves, n\u00e1ves";
export const BZ2_CAT_TROUSERS_JEANS_JOGGERS = "Nohavice, rifle, tepl\u00e1ky";
export const BZ2_CAT_TRUCKS = "N\u00e1kladn\u00e9 aut\u00e1";
export const BZ2_CAT_TSHIRTS_TOPS = "Tri\u010dk\u00e1, tielka (nie spodn\u00e1 bielize\u0148)";
export const BZ2_CAT_TUTORING = "Dou\u010dovanie";
export const BZ2_CAT_TWINS = "Oble\u010denie a obuv";
export const BZ2_CAT_TWINS_GROUP = "Dvoji\u010dky";
export const BZ2_CAT_TWINS_MISC = "R\u00f4zne (okrem ko\u010d\u00edkov)";
export const BZ2_CAT_UNDERWEAR = "Spodn\u00e1 bielize\u0148";
export const BZ2_CAT_VETERAN_VEHICLES = "Veter\u00e1ny";
export const BZ2_CAT_VIDEO_AND_PHOTOGRAPHY = "Foto, film";
export const BZ2_CAT_WEIGHT_TRAINING = "Fitness, aerobic, j\u00f3ga";
export const BZ2_CAT_WEIGHT_TRAINING_CLOTHING = "Oble\u010denie a doplnky";
export const BZ2_CAT_WEIGHT_TRAINING_OTHER = "In\u00e9";
export const BZ2_CAT_WORK_ABROAD = "Pr\u00e1ca v zahrani\u010d\u00ed";
export const BZ2_CAT_WORK_CLOTHES_FOOTWEAR = "Pracovn\u00e9 odevy, pracovn\u00e1 obuv";
export const BZ2_ESHOP_PRODUCT_HOVER = "E-shop";
export const BZ2_FACET_CATEGORY = "Kateg\u00f3ria";
export const BZ2_FACET_CATS = "Kateg\u00f3ria";
export const BZ2_FACET_GROUPS = "Moje skupiny";
export const BZ2_FACET_LOCATION = "Lokalita";
export const BZ2_FACET_LOCATIONS = "Okres";
export const BZ2_FACET_P_AGE_GROUP = "Vek";
export const BZ2_FACET_P_AGE_MONTHS = "Ve\u013ekost (vek)";
export const BZ2_FACET_P_AGE_YEARS = "Vek";
export const BZ2_FACET_P_ANIMAL = "Zviera";
export const BZ2_FACET_P_BRACES = "Traky";
export const BZ2_FACET_P_BRAND = "Zna\u010dka";
export const BZ2_FACET_P_BRAND_MODEL = "Model";
export const BZ2_FACET_P_BRAND_MODELS = "Model";
export const BZ2_FACET_P_COLOR = "K\u013e\u00fa\u010dov\u00e1 farba";
export const BZ2_FACET_P_CUP_SIZE = "Ko\u0161\u00edk";
export const BZ2_FACET_P_DATA_CARRIER = "Nosi\u010d";
export const BZ2_FACET_P_DRESSING = "Obliekanie";
export const BZ2_FACET_P_EDUCATION = "Vzdelanie";
export const BZ2_FACET_P_FIT = "Uchytenie";
export const BZ2_FACET_P_FITS_ON_MODEL = "Pasuje na";
export const BZ2_FACET_P_FOR_WHOM = "Pre koho";
export const BZ2_FACET_P_FREQUENCY = "Frekvencia";
export const BZ2_FACET_P_FUEL = "Palivo";
export const BZ2_FACET_P_GAMING_PLATFORM = "Hern\u00e1 platforma";
export const BZ2_FACET_P_HEEL = "Podp\u00e4tok";
export const BZ2_FACET_P_HOMEOFFICE = "Homeoffice";
export const BZ2_FACET_P_HOOD = "Kapuc\u0148a";
export const BZ2_FACET_P_ISOFIX = "Isofix";
export const BZ2_FACET_PLACES = "Obec";
export const BZ2_FACET_P_LANGUAGE = "Jazyk";
export const BZ2_FACET_P_LAYERS = "Vrstvy";
export const BZ2_FACET_P_LENGTH = "D\u013a\u017eka";
export const BZ2_FACET_P_LENGTH_ORDINAL = "D\u013a\u017eka (cm)";
export const BZ2_FACET_P_LEVEL = "\u00darove\u0148";
export const BZ2_FACET_P_MANUFACTURER = "V\u00fdrobca";
export const BZ2_FACET_P_MATERIAL = "Materi\u00e1l";
export const BZ2_FACET_P_MATERNITY_LEAVE_SUITABLE = "Vhodn\u00e9 popri materskej";
export const BZ2_FACET_P_NECKLINE = "V\u00fdstrih";
export const BZ2_FACET_P_NUMBER_OF_DOORS = "Po\u010det dver\u00ed";
export const BZ2_FACET_P_NUMBER_OF_SEATS = "Po\u010det miest";
export const BZ2_FACET_P_OCCASION = "Pr\u00edle\u017eitos\u0165";
export const BZ2_FACET_P_PLACE = "Miesto";
export const BZ2_FACET_P_PLACEMENT = "Umiestnenie";
export const BZ2_FACET_P_PRACTICE_LENGTH = "D\u013a\u017eka praxe";
export const BZ2_FACET_P_PRICE = "Cena v EUR";
export const BZ2_FACET_P_QUALITY = "Kvalita";
export const BZ2_FACET_P_SCHOOL_SUBJECT = "Predmet";
export const BZ2_FACET_P_SCREEN_SIZE = "Uhloprie\u010dka";
export const BZ2_FACET_P_SEASON = "Ro\u010dn\u00e9 obdobie";
export const BZ2_FACET_P_SEAT_FACING = "Sedenie";
export const BZ2_FACET_P_SEX = "Pre koho";
export const BZ2_FACET_P_SEX_TWIN = "Pohlavie";
export const BZ2_FACET_P_SHAPE = "Strih";
export const BZ2_FACET_P_SIZE = "Ve\u013ekos\u0165";
export const BZ2_FACET_P_SIZE_INTERVAL = "Ve\u013ekos\u0165";
export const BZ2_FACET_P_SIZE_WEIGHT = "Ve\u013ekos\u0165";
export const BZ2_FACET_P_SLEEPING_PLACES = "Miest na spanie";
export const BZ2_FACET_P_SLEEVE = "Ruk\u00e1v";
export const BZ2_FACET_P_SPACE_TYPE = "Typ priestoru";
export const BZ2_FACET_P_STYLE = "\u0160t\u00fdl";
export const BZ2_FACET_P_SUBJECT = "\u017d\u00e1ner";
export const BZ2_FACET_P_THEMA = "T\u00e9ma";
export const BZ2_FACET_P_TRANSMISSION = "Prevodovka";
export const BZ2_FACET_P_TYPE = "Typ";
export const BZ2_FACET_P_USAGE = "Pou\u017eitie";
export const BZ2_FACET_P_WEIGHT = "Hmotnos\u0165";
export const BZ2_FACET_P_WIDTH_ORDINAL = "\u0160\u00edrka (cm)";
export const BZ2_FACET_P_WORKING_TIME = "\u00dav\u00e4zok";
export const BZ2_FACET_P_YEAR_OF_MANUFACTURE = "Rok v\u00fdroby";
export const BZ2_FACET_QUALITY = "Pou\u017eit\u00fd tovar / Vlastn\u00e1 v\u00fdroba / Nov\u00fd tovar";
export const BZ2_FACET_SCREEN_SIZE = "Uhloprie\u010dka";
export const BZ2_HANDMADE_PRODUCT_HOVER = "Vlastn\u00e1 v\u00fdroba";
export const BZ2_JOBS_PRODUCT_HOVER = "Pr\u00e1ca";
export const BZ2_NEW_PRODUCT_HOVER = "Nov\u00fd tovar";
export const BZ2_OLD_PRODUCT_HOVER = "Pou\u017eit\u00fd tovar";
export const BZ2_SERVICES_PRODUCT_HOVER = "Slu\u017eby";
export const BZ2_SORT_COMPLAINTS = "Najviac nahl\u00e1sen\u00e9";
export const BZ2_SORT_COMPLAINTS_ASC = "Najmenej nahl\u00e1sen\u00e9";
export const BZ2_SORT_COMPLAINT_TIME = "Nahl\u00e1sen\u00e9: ned\u00e1vno";
export const BZ2_SORT_COMPLAINT_TIME_ASC = "Nahl\u00e1sen\u00e9: d\u00e1vno";
export const BZ2_SORT_PRICE = "Cena: najlacnej\u0161ie";
export const BZ2_SORT_PRICE_DESC = "Cena: najdrah\u0161ie";
export const BZ2_SORT_RECENT_ADDED = "Pridan\u00e9: Ned\u00e1vno";
export const BZ2_SORT_RECENT_UPDATED = "Aktualizovan\u00e9: Ned\u00e1vno";
export const BZ2_SORT_SCORE = "Popul\u00e1rne";
export const BZ2_SORT_SCORE_NEW = "Relevantn\u00e9";
export const BZ2_SORT_WATCHDOG = "Ulo\u017een\u00e9 h\u013eadanie";
export const BZ2_SORT_WISHLIST_ADDED_ASC = "Ulo\u017een\u00e9: d\u00e1vno";
export const BZ2_SORT_WISHLIST_ADDED_DESC = "Ulo\u017een\u00e9: ned\u00e1vno";
export const PRODUCT_FILTER_ACTUALIZABLE = "Daj\u00fa sa aktualizova\u0165";
export const PRODUCT_FILTER_ALL = "V\u0161etko (okrem zmazan\u00fdch)";
export const PRODUCT_FILTER_DELETED = "Zmazan\u00e9";
export const PRODUCT_FILTER_DISPLAYING = "Zobrazuj\u00fa sa";
export const PRODUCT_FILTER_EXPIRED = "Exspirovan\u00e9 (28 dn\u00ed +)";
export const PRODUCT_FILTER_NONDISPLAYING = "Nezobrazuj\u00fa sa";
export const PRODUCT_FILTER_NOTANSWERED = "Kde som neodpovedala";
