/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

import { d } from './_utils';


export const home_cooking_baking_ware : {[key: string]: Array<[string, string]>} = {
    p_type: [
        ["COOKWARE", "Hrnce, panvice, woky"],
        ["BAKEWARE", "Plechy a formy na pe\u010denie"],
        ["OVENDISH", "Zapekacie misy a pek\u00e1\u010de"],
        ["CHOCOLATE_MOLDS", "Formy "],
        ["MIXING_BOWLS", "Misky na mie\u0161enie"],
        ["KNIVES", "No\u017ee a \u0161krabky"],
        ["CHOPPING_BOARDS", "Dosky na kr\u00e1janie"],
        ["MEASURING_TOOLS", "Odmerky, v\u00e1hy a teplomery"],
        ["SPATULAS_PASTRY_BRUSHES", "Stierky, karty, \u0161tetce"],
        ["PASTRY_CUTTERS", "Vykrajov\u00e1tka, vykrajova\u010de"],
        ["CAKE_SCULPTING_TOOLS", "Cukr\u00e1rske pom\u00f4cky"],
        ["PASTRY_BAGS", "Zdobiace vreck\u00e1 a \u0161pi\u010dky"],
        ["TEXTURES_ROLLING_PINS_SCHEETS", "Val\u010deky a podlo\u017eky na pe\u010denie"],
        ["MODELLING_PASTES", "Cukr\u00e1rske hmoty"],
        ["CAKE_DECORATIONS", "Ozdoby a dekor\u00e1cie"],
        ["EDIBLE_COLORS_GLUE", "Farby a lepidl\u00e1"],
        ["PAPER_BAKING_PRODUCTS", "Ko\u0161\u00ed\u010dky a papierov\u00fd tovar"],
        ["GRATER", "Str\u00fahadlo, mlyn\u010dek"],
        ["LADLE_WOODEN_SPOON", "Nabera\u010dka, varecha"],
        ["OTHER_COOKING_BAKING_WARE", "In\u00e9"],
    ],
};
